<template>
  <div class="home">
    <el-card>
      <div slot="header">
        <span>{{ $t("add.uploadQ") }}</span>
        <el-button type="text" style="margin-left: 20px" @click="$router.go(-1)"> 返回</el-button>

        <el-button type="text" style="margin-left: 20px" @click="updateAudit" v-if="editFlag && form.auditStatus == 2">
          撤回审核</el-button>
      </div>
      <div class="uploadList">
        <el-upload v-if="!editFlag" class="upload-demo" drag :action="$uploadUrl + '/common/upload'"
          :on-success="handleThumbnail" multiple :show-file-list="false" :headers="token">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">{{ $t("add.clickAddQ") }}</div>
          <!-- <div class="el-upload__text">{{ $t("add.PNGformat") }}</div> -->
        </el-upload>

        <div class="imgList">
          <div class="imgItem" v-for="(item, index) in imgList" :key="index">
            <img :src="item" alt="" />
            <el-button type="text" @click="delImg(index)" v-if="!editFlag">删除</el-button>
          </div>
        </div>
      </div>
    </el-card>

    <el-form ref="form" :model="form" class="form" style="display: flex; flex-wrap: wrap" :disabled="editFlag">
      <el-card>
        <div class="flex wrap jsb clearfix">
          <el-form-item :label="$t('details.profile')" prop="section">
            <el-input v-model="form.section" dd="请输入剖面" />
          </el-form-item>
          <el-form-item :label="$t('details.reference')" prop="reference">
            <el-input v-model="form.reference" dd="请输入文献" />
          </el-form-item>



          <el-form-item :label="$t('details.dataSource')" prop="dataSource">
            <el-input v-model="form.dataSource" dd="请输入数据来源" />
          </el-form-item>
          <el-form-item label="数据来源-laboratory" prop="dataSourceEn">
            <el-input v-model="form.dataSourceEn" dd="请输入数据来源-laboratory" />
          </el-form-item>
          <el-form-item label="Scanner and type" prop="scannerTypeEn">
            <el-input v-model="form.scannerTypeEn" dd="请输入Scanner and type" />
          </el-form-item>
          <el-form-item :label="$t('details.resolution')" prop="resolution">
            <el-input v-model="form.resolution" dd="请输入解析度" />
          </el-form-item>
          <el-form-item label="解析度-英文" prop="resolutionEn">
            <el-input v-model="form.resolutionEn" dd="请输入解析度-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.dataVolume')" prop="dataVolume">
            <el-input v-model="form.dataVolume" dd="请输入数据量" />
          </el-form-item>
          <el-form-item :label="$t('details.dataFormat')" prop="dataFormat">
            <el-input v-model="form.dataFormat" dd="请输入数据格式" />
          </el-form-item>

          <el-form-item :label="$t('details.keyWords')" prop="keyWords">
            <el-input v-model="form.keyWords" dd="请输入关键词" />
          </el-form-item>
          <el-form-item :label="$t('details.dataDescription')" prop="description">
            <el-input v-model="form.description" dd="请输入内容" />
          </el-form-item>
          <el-form-item label="拍摄地" prop="keyWords">
            <el-input v-model="form.locality" dd="" />
          </el-form-item>
          <el-form-item label="拍摄地-英文" prop="keyWords">
            <el-input v-model="form.localityEn" dd="" />
          </el-form-item>
          <el-form-item :label="$t('details.age')" prop="age">
            <el-input v-model="form.age" dd="请输入时代-期" />
          </el-form-item>
          <el-form-item label="时代-英文" prop="ageEn">
            <el-input v-model="form.ageEn" dd="请输入时代-英文" />
          </el-form-item>
        </div>
      </el-card>
    </el-form>

    <div class="btn" @click="goPublish" v-if="!editFlag">
      {{ $t("add.issue") }}
    </div>
  </div>
</template>

<script>
import ImageApi from "@/api/image/index";
import { getToken } from "@/utils/auth";
import commonApi from "@/api/common/index";

export default {
  name: "myindex",

  data() {
    return {
      editFlag: false,
      form: {},
      imgList: [],
      token: {},
    };
  },
  computed: {},
  watch: {
    $route(to) {
      if (to.query) {
        this.form = {};
        this.imgList = [];
        this.$route.query.id && this.initImg(this.$route.query.id);
      }
    },
  },
  methods: {

    async updateAudit() {
      const res = await commonApi.updateAudit({ auditId: this.form.auditId });
      if (res.code == 200) {
        this.editFlag = false;
        this.$route.query.id && this.initImg(this.$route.query.id);
      }
    },
    async initImg(id) {
      const { data: res } = await ImageApi.UserPanoramaInfo(id);
      this.form = res;
      this.imgList = this.form.panoramaUrl.split("&");
      if (this.form.auditStatus == 2) {
        this.editFlag = true;
      }
    },

    async goPublish() {
      if (this.$isObjEqual(this.form)) {
        return this.$message.error("请填写古生物分类信息");
      }
      this.form.panoramaUrl = this.imgList.join("&");

      if (this.form.panoramaId != null) {
        const res = await ImageApi.editPanorama(this.form);
        if (res.code == 200) {
          this.$message.success("修改成功");
          this.editFlag = false;
          this.imgList = [];
          this.$confirm("是否提交审核?", "提示", {
            confirmButtonText: "提交审核",
            cancelButtonText: "返回",
            type: "warning",
          })
            .then(() => {
              this.$message({
                type: "success",
                message: "提交成功!",
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
          this.$router.push({
            path: "user",
          });
        }
      } else {
        const res = await ImageApi.addPanorama(this.form);
        if (res.code == 200) {
          this.form = {};
          this.$message.success("添加成功");
          this.editFlag = false;
          this.imgList = [];
          this.$router.push({
            path: "/",
          });
        }
      }
    },
    handleThumbnail(res) {
      this.imgList.push(res.url);
    },
    delImg(index) {
      this.imgList.splice(index, 1);
    },
  },
  created() {
    this.token = {
      Authorization: getToken(),
    };
    this.$route.query.id && this.initImg(this.$route.query.id);
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 1400px;
  margin: 0 auto;
  margin-top: 14px;
  padding-bottom: 50px;
  height: auto !important;

  .el-card {
    padding: 0 20px;
    margin-top: 20px;
  }

  .form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .el-input {
      width: 240px;
    }
  }

  .btn {
    width: 572px;
    height: 50px;
    background: #2b82e3;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    line-height: 50px;
    cursor: pointer;
    margin: 35px auto;
  }

  .uploadList {
    display: flex;
    padding-bottom: 50px;

    .imgList {
      display: flex;

      .imgItem {
        width: 312px;
        height: 182px;
        margin-left: 28px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.imgList {
  display: flex;
  flex-wrap: wrap;

  .imgItem {
    width: 312px;
    height: 182px;
    margin-left: 28px;
    margin-bottom: 40px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
